import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {


  const clearCookies=()=>{
     Cookies.remove('kayus_code')	
    Cookies.remove('kayus_token')
    localStorage.setItem('_kayus_expt', Date.now())
    localStorage.setItem('_kayus_bexpt', Date.now())
  }

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut