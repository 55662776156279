import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, ServerUrl, Token, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { sort } from '../component/globalFunction'
import {Type} from 'react-bootstrap-table2-editor';
 const ExamSettings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    
  const group  = useSelector(state => state.rootReducer.group);	
const [isSubmitting, setIsSubmitting] = useState(false); 

const initials = {
    grade:'',
    classtype:[],
    gradeId:[],
    maximum:'',
    minimum:'',
    gradeMark:'',
    gradeRemark:'',
    assesment:'',
    assesmentName:'',
    assesmentGrade:'',
    comment:''
}
const [master, setMaster] = useState(initials)

const [grade, setGrade]=useState([])
const [grademark, setGrademark]=useState([])
const [gradeRemark, setGradeRemark]=useState([])
const [comment, setComment]=useState([])
const [errors, setErrors] = useState({});
const [activeStep, setActiveStep] = useState(1);

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const steps = [{title: 'Create Grade'}, {title: 'Create Remark'}, {title: 'Create Grade Mark'}, {title: 'Comment List'}]


    const handleOnClickNext = useCallback(() => {
        handleUpdateSettings(false)
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])

    const  handleSelect=(option, action)=>{
      setMaster({...master, [action.name] : option });
       setErrors({...errors, [action.name]:''})
    }
    
   
    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }
    



const fetchComment =()=>{
        var sql ="SELECT grade, gradeRemark, gradeMark, comments from tbl_exam_settings where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length>0){
    var commen = result.data[0].comments
    var grad = result.data[0].grade
    var gradeRemar = result.data[0].gradeRemark
    var gradeMar = result.data[0].gradeMark
    setGradeRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
    setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
    setGrade(JSON.parse(grad).sort(sort))
    setComment(JSON.parse(commen))
    }
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}

    const getGrade=(code)=>{
        const result = grade && grade.filter(list=>list.code===code);
      const answer = result.map((c)=>c.gradeName);
      return  answer
    }

    const getGradeRemark=(code)=>{
        const result = gradeRemark && gradeRemark.filter(list=>list.code===code);
      const answer = result.map((c)=>c.remarkName);
      return  answer
    }
    function submitGradeRemark(event){ 
        event.preventDefault();
        if(!master.gradeRemark){
            setErrors({...errors, gradeRemark:'Remark field is Required'})
        }else{
            var code = 'r'+Math.random().toString(36).substr(2,9)
            setGradeRemark(prev=>{
                 return [...prev, {code:code, remarkName:master.gradeRemark, order:gradeRemark.length+1}].sort(sort)
            })
            setMaster({...master, gradeRemark:''})
        }
    }



    function submitGrade(event){ 
        event.preventDefault();
        if(!master.grade){
            setErrors({...errors, grade:'Exam grade field is Required'})
        }else{
            var code = 'g'+Math.random().toString(36).substr(2,9)
            setGrade(prev=>{
                 return [...prev, {code:code, gradeName:master.grade, order:grade.length+1}].sort(sort)
            })
            setMaster({...master, grade:''})
        }
    }

    function submitComment(event){ 
        event.preventDefault();
        if(!master.comment){
            setErrors({...errors, comment:'Comment field is Required'})
        }else{
            var code = 'c'+Math.random().toString(36).substr(2,9)
            setComment(prev=>{
                 return [...prev, {code:code, comment:master.comment}]
            })
            setMaster({...master, comment:''})
        }
    }

  const handleSubmitGradeMark=(event)=>{
    event.preventDefault();
    setErrors(ValidateErrors(master));
    setIsSubmitting(true); 
  } 


  const ValidateErrors=(values)=>{
    let errors = {};    
    if(!values.maximum){
        errors.maximum ='Please enter maximum score';
    }
    if(!values.minimum){
        errors.minimum ='Please enter minimum score';
    }
    if(values.gradeMark.length===0){
        errors.gradeMark ='Please select grademark';
    }
    if(values.classtype.length===0){
        errors.classtype ='Please select class type';
    }
    if(values.gradeId.length===0){
        errors.gradeId ='Please select grade';
    }

    return errors;
    }
    
    function submitGrademark(){   

        var code = 'gm'+Math.random().toString(36).substr(2,9)
        
        setGrademark(prev=>{
             return [...prev, {code:code, classtype:master.classtype.value, gradeCode:master.gradeId.value, maximum:master.maximum, minimum:master.minimum, gradeMark:master.gradeMark.value}].sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1)
        })

        setMaster(initials)
        setIsSubmitting(false)
 //handleUpdate
  }
    
     

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submitGrademark()
    }
    },[errors])


const  handleUpdateSettings=(data)=>{  
    
    if(grade.length>0 && gradeRemark.length>0 && grademark.length>0){
    const fd = new FormData(); 
    fd.append('grade', JSON.stringify(grade));
    fd.append('gradeMark', JSON.stringify(grademark));
    fd.append('gradeRemark', JSON.stringify(gradeRemark));
    fd.append('comments', JSON.stringify(comment));
    fd.append('jwt', Token);
    setNotice({...notice, 
      isLoading: true}) 

       let url = ServerUrl+'/update_controller/tbl_exam_settings';
      axios.post(url, fd, config)
      .then(response =>{
          if(data===true){
        if (response.data.type ==='info'){
            Alerts('Saved!', 'info', response.data.message)
        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }
}  
    })
    .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
}
}
  
    const handleDelete = (code, table)=>{ 
        if(table==='grade'){
            setGrade(grade.filter(item=>item.code!==code).sort(sort))
        }else if(table==='gradeRemark'){
            setGradeRemark(gradeRemark.filter(item=>item.code!==code))
            
        }else if(table==='grademark'){
            setGrademark(grademark.filter(item=>item.code!==code))
        }else if(table==='comment'){
            setComment(comment.filter(item=>item.code!==code))
        }
    }

    const BasicTable=(props)=>{
        const handleUpdate=(column, newValue, code)=>{   
            
            var table = props.table
    
            if(table==='grade'){
                var allData = grade.filter(item=>item.code!==code)
                let edit = grade.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setGrade(edit.concat(allData).sort(sort))
    
            }else if(table==='gradeRemark'){
                var allData = gradeRemark.filter(item=>item.code!==code)
                let edit = gradeRemark.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setGradeRemark(edit.concat(allData).sort(sort))
    
            }else if(table==='comment'){
                var allData = comment.filter(item=>item.code!==code)
                let edit = comment.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setComment(edit.concat(allData).sort(sort))
    
            }else{
                var allData = grademark.filter(item=>item.code!==code)
                let edit = grademark.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setGrademark(edit.concat(allData).sort(sort))
    
            }
         } 
    
    
         const { SearchBar } = Search;
             
              const customTotal = (from, to, size) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search  className="table-responsive"
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 <SearchBar
                                 { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
     
    
      const tableGradeRemarkHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'gradeRemark')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false},
        {dataField: 'remarkName', text: 'Remark',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Remark field is required'
              };
            }
            return true;
          } }     
     ];


        const tableGradeHeader = [
            {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'grade')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false},
            {dataField: 'gradeName', text: 'Grade',  sort: true, validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Grade field is required'
                  };
                }
                return true;
              } },
              {dataField: 'order', text: 'order',  sort: true, validator: (newValue, row, column) => {
                if (!Number(newValue)) {
                  return {
                    valid: false,
                    message: 'Order field required a valid number'
                  };
                }
                return true;
              } }      
         ];

         const tableCommentHeader = [
            {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'comment')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false},
            {dataField: 'comment', text: 'Comment',  sort: true, validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Comment field is required'
                  };
                }
                return true;
              } }     
         ];

     const tableGradeMarkHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'grademark')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false},
        
        {dataField: 'classtype', text: 'Class',  sort: true, editor: {
            type: Type.SELECT, options:[
                        {value:'Creche', label:'Creche'},
                        {value:'Pre-Nursery', label:'Pre-Nursery'},
                        {value:'Nursery', label:'Nursery'},
                        {value:'Primary', label:'Primary'},
                        
                        {value:'Junior Secondary School', label:'Junior Secondary School'},
                        {value:'Senior Secondary School', label:'Senior Secondary School'}
            ] } },

        {dataField: 'gradeCode', text: 'Grade', editable: false,  formatter: (cell)=>getGrade(cell)},

        {dataField: 'maximum', text: 'Max Score',  sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          } },
          {dataField: 'minimum', text: 'Min Score',  sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          } },

          {dataField: 'gradeMark', text: 'Remark',  sort: true, editable: false,  formatter: (cell)=>getGradeRemark(cell)}     
     ];



     useEffect(()=>{
       
        setIsAuthenticated(menuLink.includes(location.pathname)) 
        fetchComment()
     },[]);

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

        <PageHeader title="Result Settings">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Exam Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '50px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="grade">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter Grade' name='grade' onChange={handleChange} value={master.grade} className={errors.grade ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitGrade}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.grade && errors.grade}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={grade}  table={'grade'} columns={tableGradeHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  :  activeStep === 2 ? 

      <div className="row setup-content" id="gradeRemark">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter Grade Remark' name='gradeRemark' onChange={handleChange} value={master.gradeRemark} className={errors.gradeRemark ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitGradeRemark}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.gradeRemark && errors.gradeRemark}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={gradeRemark}  table={'gradeRemark'} columns={tableGradeRemarkHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>

      :  activeStep===3?
      
    
      <div className="row setup-content" id="gradeMark">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">
  <div className="card-block">
  
<div  className="row">
<section className="col-md-2"><label >Class </label></section>
     <section className="col-md-2"><label >Select Grade </label></section>
     <section className="col-md-2"><label >Maximum Score</label></section>
     <section className="col-md-2"><label >Minimum Score</label></section>
     <section className="col-md-2"><label >Remark </label></section>     
     </div>



     <div  className="row">
      <section className="col-md-2">

      <div className="form-group">
                        <Select options={
                            [{value:'Creche', label:'Creche'},
                            {value:'Pre-Nursery', label:'Pre-Nursery'},
                            {value:'Nursery', label:'Nursery'},
                            {value:'Primary', label:'Primary'},
                            
                            {value:'Junior Secondary School', label:'Junior Secondary School'},
                            {value:'Senior Secondary School', label:'Senior Secondary School'}
                        
                        ]
                            
                            } 
                             
onChange={handleSelect} name="classtype" value={master.classtype} className={errors.classtype ? 'form-control form-control-danger' : ''} styles={selectStyles}  /> 
			</div>

            </section> 

            <section className="col-md-2">
      <div className="form-group">
                        <Select options={
                            grade&&grade.map((lst, idx)=> {
                               return {key:idx, value: lst.code, label: lst.gradeName}
                             })} 
                             
onChange={handleSelect} name="gradeId" value={master.gradeId} className={errors.gradeId ? 'form-control form-control-danger' : ''} styles={selectStyles}  /> 
			</div>
</section>  



<section className="col-md-2">
<div className="form-group">
                <input type="number" min="0" placeholder='Maximum Mark' name='maximum' onChange={handleChange} value={master.maximum} className={errors.maximum ? 'form-control form-control-danger' : 'form-control'} />
       </div>
</section> 

<section className="col-md-2">
<div className="form-group">
    <input type="number" min="0" placeholder='Minimum Mark' name='minimum' onChange={handleChange} value={master.minimum} className={errors.minimum? 'form-control form-control-danger' : 'form-control'} />
       </div>
</section>  

<section className="col-md-2">
<div className="form-group">
                        <Select  
                options={
                    gradeRemark&&gradeRemark.map((lst, idx)=> {
                       return {key:idx, value: lst.code, label: lst.remarkName}
                     })}
        onChange={handleSelect}  name="gradeMark" value={master.gradeMark} className={errors.gradeMark ? 'form-control form-control-danger' : ''} styles={selectStyles} /> 
			</div>
</section> 
  
        <section className="col-md-2"> 
     <button type="button" onClick={handleSubmitGradeMark} className="btn btn-primary waves-effect"  id="saveRange"  >Save</button>
     </section>

     </div>      
</div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {
   <BasicTable  data={grademark} table={'grademark'} columns={tableGradeMarkHeader}  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>:

<div className="row setup-content" id="comment">
		    
            <div className="col-md-12">
     
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12">
     
            <div className="form-group">
                <div className="input-group">
                            <input type="text" placeholder='Enter Comment' name='comment' onChange={handleChange} value={master.comment} className={errors.comment ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                           <button className="btn btn-inverse" type="button" onClick={submitComment}>Add</button>
                   </div>
                 <span style={{color:'red'}}>{errors.comment && errors.comment}</span>
                </div>		
           </section>
               </div>
                                    </div>
                                    
            </div>	
                  <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12 table-responsive">
            {notice.isDataFetching ? <TableLoader />:
        <BasicTable data={comment}  table={'comment'} columns={tableCommentHeader}  />} 
            </section>
                    </div>
                    </div>
            
            </div>	</div>
            
            </div>	
                 
            </div>
         
     </div>}

     <button onClick={activeStep === steps.length ? ()=>handleUpdateSettings(true) : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ExamSettings) 