import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import { FormatNumber, getCAPoint, longDate} from '../component/globalFunction'
import { formatGroupLabel} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'
const ClassMarks =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
  
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);  
const staff  = useSelector(state => state.rootReducer.staff);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [classSubject, setClassSubject]=useState([]);
    const [allSubject, setAllSubject]=useState([]);
    const [sqlQuery, setSqlQuery] = useState([]);
   
    const [subjectAverage,  setSubjectAverage] = useState([]);
    const [classTeacher, setClassTeacher] = useState([]);
    const [classresult, setClassresult]= useState([])
    const [grossPoint, setGrossPoint]= useState([])

    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        orderBy:{value:'Full', label:'End of Term'}
    })

    
    const handleSearch = event =>{
        event.preventDefault();  
    var errormessage = [];

    if(result.studentClass.length===0){
        var studentClass ='Please select class';
        setErrors({...errors, studentClass:studentClass})
        errormessage.push(studentClass);

    }

    if(result.examtype.length===0){

        var examtype ='Please select exam';
        setErrors({...errors, examtype:examtype})
        errormessage.push(examtype);
    }


    if (errormessage.length<=0) {
        fetchResult()
    }

    }  




    const fetchGrossPoint =()=>{
        var sql ="SELECT grossPoint, grade from tbl_exam_settings where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length>0){
    var grossP = result.data[0].grossPoint
    setGrossPoint(JSON.parse(grossP))
    }
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}


const fetchClassTeaher =(classID, sectionID)=>{
    var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
    setClassTeacher(newStaff)
}


    const getCgpa =[{"min":"0", "max":"29.49", "remark":"UNGRADED", "gp":"0"},
        {"min":"29.50", "max":"39.49", "remark":"UNGRADED", "gp":"0"},
        {"min":"39.50", "max":"49.49", "remark":"POOR", "gp":"0.50"},
        {"min":"49.50", "max":"54.49", "remark":"UNSATISFACTORY", "gp":"1.00"},
        {"min":"54.5", "max":"59.49", "remark":"UNSATISFACTORY", "gp":"1.50"},
        {"min":"59.5", "max":"64.49", "remark":"SATISFACTORY", "gp":"2.00"},
        {"min":"64.5", "max":"69.49", "remark":"SATISFACTORY", "gp":"2.50"},
        {"min":"69.5", "max":"74.49", "remark":"SATISFACTORY", "gp":"2.75"},
        {"min":"74.5", "max":"79.49", "remark":"GOOD", "gp":"3.00"},
        {"min":"79.5", "max":"84.49", "remark":"GOOD", "gp":"3.50"},
        {"min":"84.5", "max":"90.49", "remark":"GOOD", "gp":"3.75"},
        {"min":"90.5", "max":"94.49", "remark":"EXCELLENT", "gp":"4.00"},
        {"min":"94.5", "max":"100", "remark":"EXCELLENT", "gp":"4.00"}]

      


    const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)
    const getGrade =(score, field)=>{
   
        const ans = grossPoint&&grossPoint.filter(itm=>parseFloat(score) >= parseFloat(itm.min) && parseFloat(score) <= parseFloat(itm.max));


        const remark = ans.length!==0? ans[0].remark:''
        const grade = ans.length!==0? ans[0].gp:''
       
    return field ==='grade'?grade:remark
    }

        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();

    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const  handleFetchAllSubject =()=>{
        
    const fd = new FormData(); 
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_subject'
        axios.post(url, fd, config)
    .then(result => setAllSubject(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }) }


    const getSubjectName = (code, field) =>{        
        const response = allSubject&&allSubject.filter(list=>list.code === code);
        return   String(response.map(c=>c[field]));
        }
        



    const fetchClassSubject =(sectionID, exam)=>{
        
   
        var sql ="SELECT  subjectCode  from tbl_exam_result  where examCode ='"+exam+"' and sectionID ='"+sectionID+"' group by subjectCode";

        
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0 && Array.isArray(response.data)){

            var subj = response.data;
    var subjectList = [];
    for (var i = 0; i< subj.length;  i++) {
        subjectList.push(
            {  subjectCode:subj[i].subjectCode,
                subjectName:getSubjectName(subj[i].subjectCode, 'subjectName')
            });
            }

            setClassSubject(subjectList)






            let final = '';
if(result.orderBy.value==='Full'){
        let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
               


          final =   'SELECT  sum(case when e.total >0 then 1 else 0 end) as subject, rank() over (order by  cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) desc) position,  s.admissionNumber, s.termination, s.termination_date, s.termination_remark,  sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '

          
}else{

                    let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then  (((e.firstCA + e.secondCA)/20)*100) else 0 end) AS '"+data.subjectName+"'")
                       

     
     final =   'SELECT  sum(case when (e.firstCA + e.secondCA) >0 then 1 else 0 end) as subject, rank() over (order by cast(sum(case when (e.firstCA + e.secondCA)>0 and e.absent="No" then  (((e.firstCA + e.secondCA)/20)*100) else 0 end)/sum(case when (e.firstCA + e.secondCA)>0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) desc) position,  s.admissionNumber, s.termination, s.termination_date, s.termination_remark, sum(case when (e.firstCA + e.secondCA) >0 and e.absent="No" then (((e.firstCA + e.secondCA)/20)*100) else 0 end) as obtained,  sum(case when (e.firstCA + e.secondCA) >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when (e.firstCA + e.secondCA)>0 and e.absent="No" then  (((e.firstCA + e.secondCA)/20)*100) else 0 end)/sum(case when (e.firstCA + e.secondCA)>0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.catAbsent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '

            }

        setSqlQuery(final)
        
        }
        })
    }

    const handleSelectType =(option, action)=>{     
        setResult({...result, [action.name]:option, studentClass:[], examtype:[]});
        setErrors({...errors, [action.name]:''})
        setClassresult([])
       setSqlQuery('')

    }

const handleSelect =(option, action)=>{     
          
           
            setErrors({...errors, [action.name]:''})
            setClassresult([])
            if(action.name==='studentClass'){
                fetchClassTeaher(option.classID, option.value) 
                setResult({...result, [action.name]:option, examtype:[]});
            }else if(action.name==='examtype'){
                fetchClassSubject(result.studentClass.value, option.value)
                setResult({...result, [action.name]:option});
            }
        }

        const fetchResult = async()=>{
            setNotice({...notice, isLoading: true}) 
           
         const fd = new FormData();
        fd.append("sql", sqlQuery);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
      await  axios.post(url, fd, config).then(result=>{
            
             if(result.data.length!==0){
                //obtainable
               var rs= result.data;
               var subList = [];
                for (var i = 0; i < rs.length; i++){
                    subList.push(
                            {...rs[i],
                                obtainable:parseFloat(rs[i].subject)*100,
                                cgp:getGrade(parseFloat(rs[i].average), 'grade'),
                                remark:getGrade(parseFloat(rs[i].average), 'remark')
                                
                            });
                }

               

                var averageList = [];
                for (var j = 0; j < rs.length; j++){
                    for (var m = 0; m < classSubject.length; m++){
                        if(parseFloat(rs[j][classSubject[m].subjectName])>0){
                                averageList.push(
                                    {
                                        subjectCode:classSubject[m].subjectCode,
                                        subjectName:classSubject[m].subjectName,
                                        score:rs[j][classSubject[m].subjectName]
                                        
                                    })
                                }
                        }        
                }
var count = { }
averageList&&averageList.forEach( element => {
  if ( count[ element.subjectName ]) {
    count[ element.subjectName ] += parseFloat(element.score)
    count [element.subjectName+'total'] +=1;
  } else {
    count[ element.subjectName ] = parseFloat(element.score)
    count [element.subjectName+'total'] =1;
  }
})



        setClassresult(subList)
        setSubjectAverage(count)
structure.current.scrollIntoView({ behavior: "smooth" });
            }else{
                setClassresult([])
                Alerts('Info!', 'info', 'There are no subject assigned for the selected class')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }

useEffect(()=>{
 
   setIsAuthenticated(menuLink.includes(location.pathname))
    fetchGrossPoint()
    fetchExamType()
    handleFetchAllSubject()
},[]);


        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Marks">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Marks</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Marks</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

        <section className="col-md-4">
           <div className="form-group">
         <label> Report Type</label>
         <Select  options={[
             {value:'Half', label:'Half Term'},
                    { value: 'Full', label: 'End of Term'}
                ]} 
onChange={handleSelectType}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>

                    <section className="col-md-4">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                        schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>     
    
     <section className="col-md-4">
            <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
      
      </section>
      </div>

      <div className="row">
      <div className="col-md-12 ">  
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-primary pull-right"><i className="fa fa-search"></i> Search Result</button>
		 </div>
			</div>	             
				
							</div>
                            </div>
							</div></div>
</div>

</div>

{classresult.length!==0?
<div className="card z-depth-0">
{result.orderBy.value==='Half'?
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>{result.examtype.label} (HALF TERM AT A GLANCE RESULT) </b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.studentClass.label}</b></td>
                    <td >Form Tutor: <b>{classTeacher&&classTeacher.map(item=>item.staffName+' ')}</b></td>
                </tr>
            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>


                <tr>
                    <th>S/N</th>
                    <th >Admission No.</th>
                    <th >Name of Students</th>
                    
                    {classSubject&&classSubject.map((data, id)=><th key={id} className="vertical-header"><span>{data.subjectName}</span></th>)}
                     <th className="vertical-header"><span>No. of Subjects</span></th>
                    <th className="vertical-header"><span>Mark Obtainable</span></th>
                    <th className="vertical-header"><span>Mark Obtained</span></th>
                    <th className="vertical-header"><span>Average</span></th>
                    <th className="vertical-header"><span>1ST G.P.A</span></th> 
                    <th className="vertical-header"><span>REMARK</span></th> 
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <th colSpan={2}>MARK OBTAINABLE</th>
                    {classSubject&&classSubject.map((data, id)=><th key={id} >100</th>)}
                    
                    <th>{classSubject.length}</th>
                    <th>{classSubject.length*100}</th>
                    <th></th>
                    <th>100</th>
                    <th>4.0</th>
                </tr>
                 {classresult.length!==0?classresult.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                    <td>{st.admissionNumber}</td>
                    <td>{st.studentName} <br/>{st.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(st.termination_date)} {st.termination_remark}</span>:''}</td>
                    {classSubject&&classSubject.map((data, id)=><td key={id} >{st[data.subjectName]>0?parseFloat(st[data.subjectName]).toFixed(2):'-'}</td>)}
                    
                     <td>{st.subject}</td>
                    <td>{st.obtainable}</td>
                    <td>{parseFloat(st.obtained).toFixed(2)}</td>
                    <td>{st.average}</td>
                    <td>{st.cgp}</td>
                    <td>{st.remark}</td>

                </tr>):[]} 

              <tr>
              <td></td>
                    <th colSpan={2}>SUBJECT AVERAGE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {(parseFloat(subjectAverage[st.subjectName])/subjectAverage[st.subjectName+'total']).toFixed(2)}
                   

                        </th>)} 
                    
                </tr>  
                <tr>
              <td></td>
                    <th colSpan={2}>TOTAL SCORE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {FormatNumber(subjectAverage[st.subjectName])}
                   

                        </th>)} 
                    
                </tr> 

                <tr>
              <td></td>
                    <th colSpan={2}>No. of Students</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {subjectAverage[st.subjectName+'total']}
                   

                        </th>)} 
                    
                </tr> 
            </tbody> 
        </table>
 </div>	 </div> 

 <div className="row no-print" ref={structure}>
        <div className="col-md-12">
            <form method='POST' action={ServerUrl+'/printout/class_marks.php?token='+userToken} target='_blank'>
                <input type='hidden' name='classSubject' value ={JSON.stringify(classSubject, null, 2)} />
                <input type='hidden' name='classresult' value ={JSON.stringify(classresult, null, 2)} />
                <input type='hidden' name='subjectAverage' value ={JSON.stringify(subjectAverage, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='resultType' value ={result.orderBy.value} />
                <input type='hidden' name='teacher' value ={classTeacher&&classTeacher.map(item=>item.staffName+' ')} />


                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>:

<div className="card-block">
	
{schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}

<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b>END OF {result.examtype.label} (AT A GLANCE RESULT) </b></u></h5>
</div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
        <tr>
            <td >Class: <b>{result.studentClass.label}</b></td>
            <td >Form Tutor: <b>{classTeacher&&classTeacher.map(item=>item.staffName+' ')}</b></td>
        </tr>
    </tbody>
</table></div>
<div className="col-md-12 table-responsive">
<table className="table table-striped table-bordered">


    <thead>
        <tr>
            <th>S/N</th>
            <th >Admission No.</th>
            <th >Name of Students</th>
            
            {classSubject&&classSubject.map((data, id)=><th key={id} className="vertical-header"><span>{data.subjectName}</span></th>)}
             <th className="vertical-header"><span>No. of Subjects</span></th>
            <th className="vertical-header"><span>Mark Obtainable</span></th>
            <th className="vertical-header"><span>Mark Obtained</span></th>
            <th className="vertical-header"><span>Average</span></th>
            <th className="vertical-header"><span>G.P.A</span></th> 
            <th className="vertical-header"><span>REMARK</span></th> 
        </tr>
    </thead>
    <tbody>
    <tr>
                    <td></td>
                    <th colSpan={2}>MARK OBTAINABLE</th>
                    {classSubject&&classSubject.map((data, id)=><th key={id} >100</th>)}
                    
                    <th>{classSubject.length}</th>
                    <th>{classSubject.length*100}</th>
                    <th></th>
                    <th>100</th>
                    <th>4.0</th>
                </tr>
         {classresult.length!==0?classresult.map((st, idx)=> 
        <tr key={idx}>
        <td>{idx+1}</td>
            <td>{st.admissionNumber}</td>
            <td>{st.studentName} <br/>{st.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Susspended on {longDate(st.termination_date)} {st.termination_remark}</span>:''}</td>
            {classSubject&&classSubject.map((data, id)=><td key={id} >{st[data.subjectName]>0?st[data.subjectName]:'-'}</td>)}
            
             <td>{st.subject}</td>
            <td>{st.obtainable}</td>
           <td>{parseFloat(st.obtained).toFixed(2)}</td>
            <td>{st.average}</td>
            <td>{st.cgp}</td>
            <td>{st.remark}</td>

        </tr>):[]} 
        <tr>
        <td></td>
                    <th colSpan={2} >SUBJECT AVERAGE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {(parseFloat(subjectAverage[st.subjectName])/subjectAverage[st.subjectName+'total']).toFixed(2)}
                   

                        </th>)} 
                    
                </tr> 
                <tr>
              <td></td>
                    <th colSpan={2}>TOTAL SCORE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {FormatNumber(subjectAverage[st.subjectName])}
                   

                        </th>)} 
                    
                </tr> 

                <tr>
              <td></td>
                    <th colSpan={2}>No. of Students</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {subjectAverage[st.subjectName+'total']}
                   

                        </th>)} 
                    
                </tr> 
    </tbody> 
</table>
</div>	 </div> 

<div className="row no-print" ref={structure}>
<div className="col-md-12">
    <form method='POST' action={ServerUrl+'/printout/class_marks.php?token='+userToken} target='_blank'>
        <input type='hidden' name='classSubject' value ={JSON.stringify(classSubject, null, 2)} />
        <input type='hidden' name='classresult' value ={JSON.stringify(classresult, null, 2)} />
        <input type='hidden' name='classID' value ={result.studentClass.classID} />
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
        <input type='hidden' name='examCode' value ={result.examtype.value} />

        <input type='hidden' name='subjectAverage' value ={JSON.stringify(subjectAverage, null, 2)} />
        <input type='hidden' name='resultType' value ={result.orderBy.value} />
        <input type='hidden' name='teacher' value ={classTeacher&&classTeacher.map(item=>item.staffName+' ')} />


        <input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>}


        </div>:''}
 
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ClassMarks) 