import React, {useEffect} from 'react';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';




import error403 from './settings/error403';
import error500 from './settings/error500';
import Error404 from './settings/error404';

import login from './users/login';
import LogOut from './users/logout';
import forgotpassword from './users/forgotpassword';
import {LicenseTimeout} from './component/notify'
import {  LocalUrl } from './component/include';
import recover_password from './users/recover_password';
import Template from './component/template';

const App =()=>{

  /* 
const checkLicense=()=>{
//prevent working from localhost
if(window.location.hostname ==='localhost'){
    return false;
 }else{
    return true;
  }
}

useEffect(()=>{
setTimeout(() => {
if(checkLicense()===false){
  document.getElementById('openLicenseModal').click()
//timeup logout user
}
}, 4000)
}, []) */

    return (<>
    
    <a href="#!" data-toggle="modal" id='openLicenseModal' data-target='#licenseModal' ></a>
    <LicenseTimeout   /> 
      <ReactNotification />
     <Router  >
      <Switch>
      <Route exact path="/" component={login} />
      <Route exact path="/logout" component={LogOut} />
      <Route exact path="/forgot_password" component={forgotpassword} />
    <Route exact path="/recover_password" component={recover_password} />

    <Template />
       
        <Route exact  component={Error404} />
      </Switch>
      </Router>
 </>
    );
  
}
export default App;
