import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, staffCode, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate, sort, getRank, getCAPoint} from '../component/globalFunction'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
 const ResultEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [csvFile, setCsvFile]=useState({
        studentCsv:'',
        fileName:'Please select .csv files (allowed file size 1Mb)'
    })


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
    })


    const steps = [{title: 'Search Exam'}, {title: 'Enter Score'}]
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
   
    const [examtype, setExamtype]=useState([])
    const [subject, setSubject]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [grade, setGrade] = useState([])
    const [grademark, setGrademark]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [remark, setRemark]=useState([])
const [activeStep, setActiveStep] = useState(1);
const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])


    const fetchExamType =()=>{
        var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active' and t.term <> 'MOCK EXAM' and t.publish ='No' group by r.examCode order by exam ASC" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    setExamtype(result.data)
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}

const fetchSubject =(type, section)=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+type+"' and r.sectionID ='"+section+"' group by r.subjectCode  order by r.displayOrder ASC" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){

        setSubject(result.data)
    }else{
        
setSubject([])
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
})
}











const DownloadStudentList =()=>{
    let error = {}; 
    if(result.studentClass.length===0){
        error.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        error.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        error.subject ='Please select subject';
    } 


    setErrors(error)

    if(Object.keys(error).length === 0){

       document.getElementById('exportExcel').click()

    }else{
        window.scrollTo(0,0)
    }



}







const fetchStudentList =()=>{
    
    let errors = {};   
   
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 

        
var sql ="SELECT  rank() over (order by  CAST(e.total AS decimal(10,2)) desc) rank, s.admissionNumber, s.registerNumber, s.studentName, e.examDate, e.maxMark, e.firstCA, e.thirdCA, e.fourthCA, e.fifthCA, e.sixthCA, e.absent, e.secondCA, e.exam, e.total,  e.totalAverage, e.teacher, e.grade, e.remark, e.comment, e.code from tbl_students s, tbl_exam_result e where s.code = e.studentCode and e.subjectCode = '"+result.subject.value+"' and e.examCode ='"+result.examtype.value+"' and s.admissionStatus ='Studying' and e.sectionID ='"+result.studentClass.value+"' "+result.orderBy.value

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    
    if(result.data.length!==0 && Array.isArray(result.data)){

        fetchResultAverage(result.data)       
         updateRank(result.data) 

    //staffCode

 const stf = result.data[0].teacher;
 
  if(stf.includes(staffCode)){
    setStudentList(result.data)
    setActiveStep(2)
}else{
    setStudentList([])
    Alerts('Error!', 'danger', 'Only subject teacher can record or modify exam scores ');
}   

    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
    }
}




const updateRank =(resultData)=>{
    const fd = new FormData(); 
  fd.append('subjectResult', JSON.stringify(resultData, null, 2));
fd.append('jwt', Token);
     let url = ServerUrl+'/update_controller/tbl_exam_result_rank';
    axios.post(url, fd, config)
}



const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   if(action.name==='studentClass'){
    fetchSubject(result.examtype.value, option.value)
   }else if(action.name==='examtype'){
   fetchSubject(option.value, result.studentClass.value)
   }
   setStudentList([])
  
}




const fetchGradeMark =()=>{
    var sql ="SELECT grade, gradeRemark, gradeMark from tbl_exam_settings where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
if(result.data.length>0){
var grad = result.data[0].grade
var gradeRemar = result.data[0].gradeRemark
var gradeMar = result.data[0].gradeMark
setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
setGrade(JSON.parse(grad).sort(sort))
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}


const handleChangeScore =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student); 
       } 


const handleChangeGrade =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const currentResult = student.filter((item, id)=>id===index)
   
    let {name, value} = event.target
    value = value.replace(/[^0-9]/g, '');
    var firstCA  = parseFloat(currentResult[0].firstCA)
    var secondCA = parseFloat(currentResult[0].secondCA)

    var thirdCA = parseFloat(currentResult[0].thirdCA)
    var fourthCA = parseFloat(currentResult[0].fourthCA)

    var fifthCA = parseFloat(currentResult[0].fifthCA)
    var sixthCA = parseFloat(currentResult[0].sixthCA)

    var exam = parseFloat(currentResult[0].exam)
    var total = parseFloat(currentResult[0].total)
    //var max = parseFloat(currentResult[0].maxMark)


    switch(name) {
        case 'firstCA':
            if(value<=10){
          total =  Number(value) + secondCA + thirdCA + fourthCA + exam + fifthCA + sixthCA
            }else{
            value =0
        }
          break;
          case 'secondCA':
            if(value<=10){
          total =  Number(value) + firstCA + thirdCA + fourthCA + exam + fifthCA + sixthCA
            }else{
            value =0}
          break;
            case 'thirdCA':
            if(value<=20){
            total =  Number(value) + firstCA + secondCA + fourthCA + exam  + fifthCA + sixthCA
        }else{
            value =0
        }
        break;
        case 'fourthCA':
            if(value<=5){
            total =  Number(value) + firstCA + secondCA + thirdCA + exam  + fifthCA + sixthCA
        }else{
            value =0
        }
        break;
        case 'fifthCA':
            if(value<=5){
          total =  Number(value) + firstCA + thirdCA + fourthCA + exam  + secondCA + sixthCA
            }else{
            value =0}
          break;
          case 'sixthCA':

            if(value<=10){
          total =  Number(value) + firstCA + thirdCA + fourthCA + exam + secondCA + sixthCA
            }else{
            value =0}
          break;
                  case 'exam':
                    if(value<=40){
                    total =  Number(value) + firstCA + secondCA + thirdCA + fourthCA + fifthCA + sixthCA
                }else{
                    value =0}
                      break;
                 default:
      }


    //value = value.replace(/[^0-9]/g, '');
   

    student[index][name] = value;
    student[index].total = total
    student[index].grade = getGrade(total, 'grade')
    student[index].remark = getGrade(total, 'remark')

  setStudentList(student); 
       } 

  
const getGradeScore =(code)=>{
const ans = grade.filter(item=>item.code===code)
return ans[0].gradeName
}

const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)
const getGrade =(score, field)=>{
   
    const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& roundNum(score) >= roundNum(itm.minimum) && roundNum(score) <= roundNum(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   

return field ==='grade'?String(grade):String(remark)
}

 
const handleSubmit = event =>{
    event.preventDefault();    
    
    let errors = {};   
    if(studentList.length===0){
        errors.studentClass ='There are no student in the selected class';
    } 
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
       fetchResultAverage(studentList)
    }


}

   
    

const fetchResultAverage =async(studentList)=>{
    var stdlist = [...studentList]
        var resultLength = stdlist.filter(item=>parseFloat(item.total)>0)
        var total = stdlist.reduce((acumulator, item)=>acumulator + parseFloat(item.total), 0)
        var average = total/resultLength.length
    
        if (Number(average)>0){
           
            const fd = new FormData(); 
            fd.append('subjectResult', JSON.stringify(stdlist, null, 2));
            fd.append('average', average.toFixed(2));
          fd.append('jwt', Token);
               let url = ServerUrl+'/update_controller/tbl_exam_result_average';
           await   axios.post(url, fd, config).then(response =>{
              })
    
        }
    }
    

    const submit=async()=>{
    
setNotice({...notice, 
    isLoading: true})    

    const fd = new FormData(); 
    fd.append('jwt', Token);
   fd.append('studentList', JSON.stringify(studentList, null, 2));
    let url = ServerUrl+'/update_controller/tbl_exam_result';
          
      await  axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='info'){
                Alerts('Updated!', 'default', response.data.message)
                fetchStudentList()
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              //  Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                
                  setNotice({...notice, 
                      isLoading: false })
                  
                setIsSubmitting(false);
                  setActiveStep(1)
              })   
  }
    



const handleReset=()=>{
    setResult({ 
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
        })

        setStudentList([])
}


const  handleSubmitFile=(event)=>{ 
    event.preventDefault(); 

    if(!csvFile.studentCsv){
        //errors.studentCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', 'You have not choose any file to upload')
    }else{
    
    const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append('studentCsv', csvFile.studentCsv);

     setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/update_controller/importresult'

      axios.post(url, fd, config).then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{ 
            setNotice({...notice,  isLoading: false}) 
     setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 800KB)'}); 
     document.getElementById('studentCsv').value=''
        }) 
} 

}

const  handleFile = function(fieldName){
    return function(newValue){  
       
        setErrors({...errors, [fieldName]:''})
   readURL(newValue);
   }
   
} 


const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
        var fileName = document.getElementById(PreviewImage).value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    

    if (extFile==="csv" || extFile==="CSV"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
       document.getElementById(input.target.name).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
               const value = input.target.files[0]
        setCsvFile({...csvFile, studentCsv:value, fileName:value.name})
       
        }
    }
}else{

    displayMessage = 'Only csv|CSV  files are allowed with maximum of 1Mb'
    document.getElementById(input.target.name).value = '';
    setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 1Mb)'})
}  

if(displayMessage.length > 0){
    Alerts('Error!', 'danger', displayMessage)
  }

} 


    useEffect(()=>{
       
    setIsAuthenticated(menuLink.includes(location.pathname))   
        fetchExamType()
        fetchGradeMark()
    },[]);
  

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Result Entry">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Result Entry</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



               


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Exam Result Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label><a href="/exam/result_setup" className="pull-right" >Add more exam</a>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>
     </div>
     <div className="row">
     <section className="col-md-6">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label><a href="/setup/class_subject" className="pull-right" >Add subject</a>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Order</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>
     
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 


     <footer className="pull-right">
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <div className='col-md-12 '>
           <div className='pull-right'>
               
           <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

           </div></div>
   <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                <th>SN</th>
                    <th>Student Details</th>
                   {/*  {getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?<> */}
                   
                   <th>Homework (10)</th>
                    <th>Participation (10)</th>

                    <th>1<sup>st</sup> CA ({result.studentClass.length!==0?getCAPoint(result.studentClass.classtype).ca:20})</th>

                    <th>Projects(5)</th>
                    <th>Quiz (5)</th>


                    <th>2<sup>nd</sup> CA (10)</th>
                   
                    <th>Exam ({result.studentClass.length!==0?getCAPoint(result.studentClass.classtype).exam:40})</th>
                    <th>Total (100)</th>
                    <th>Grade</th>
                    <th>Remark</th> 
                    
                    <th>Position</th>
                    {/*  </>:[]} */}
                            
                    <th>Absent</th>

                </tr>
            </thead>
            <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
                <tr key={idx}>
                    
                    <td>{idx+1}</td>

                    <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
                    
                  {/*   {getResultHead(result.studentClass.classtype)==='Pre-Nursery'||getResultHead(result.studentClass.classtype)==='Creche'? <textarea name='comment' placeholder='Subject Comment' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}

                    </textarea> :''} */}
                    
                    </td>
                    {/* {getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?<> */}

                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" id={idx} name='firstCA' value={st.firstCA}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>

                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" id={idx} name='secondCA' value={st.secondCA}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>




                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" id={idx} name='thirdCA' value={st.thirdCA}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>


                    

                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" id={idx} name='fourthCA' value={st.fourthCA}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>

                   
                   
                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" id={idx} name='fifthCA' value={st.fifthCA}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>


                    
                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" id={idx} name='sixthCA' value={st.sixthCA}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>


                    <td><input type="text" onFocus={(e)=>e.target.select()}  onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" pattern='[0-9]' min={0} step={1} id={idx} name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>

                    <td>{st.total}</td>
                    <td><b>{st.grade}</b></td>
                    <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.remark}</b></td>
                    
                    
                    <td>{st.rank}<sup>{getRank(st.rank)}</sup></td>
                  {/*   </>:[]} */}
                    <td>

                    <select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
                    <option  value='No'>No</option>
                    <option  value='Yes'>Yes</option>
					</select>

                    </td>

                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>

</div>}


     <button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Result </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>

{/* 

           {studentList.length>0?     <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Student File</h4>
					
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Result Code, Admission Number, Student Name, CA, Exams, Absent </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><div className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/result'} target='_blank'>
        <input type='hidden' name='subjectCode' value ={result.subject.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='className' value ={result.examtype.label +' '+ result.studentClass.label+' - '+result.subject.label} />
                <input type='hidden' name='orderBy' value ={result.orderBy.value} />
                
                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="display-none"  id="exportExcel"> </button>
       
     <button type="button" onClick={DownloadStudentList} className="btn btn-sm btn-inverse"><i className="fa fa-download"></i> Download File</button>
        </form>
        
					</div>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extension</h4>
							<section>
                            <div className="form-group">
							
                    <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control" name="studentCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset></div>
        <footer>
            <button type="button" id="submit" onClick={handleSubmitFile} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Student Result</button>
        </footer>

	</div>

</div>

</div></div>:''}
 */}

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultEntry) 